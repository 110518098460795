const binding = { modules: {}, dataActions: {} };

    (binding.modules['css-custom'] = {
        c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/adventureworks'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['cart-icon','cart','header','navigation-menu','search-result-container','tile-list-item'],
        iNM: true,
        ns: '__local__',
        n: 'css-custom',
        p: '__local__',
        
        pdp: 'node_modules/@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/adventureworks.definition.json',
        
        themeSettings: 'css-custom.theme.settings.json',
        md: 'src/themes/css-custom'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };